<template>

      <div  v-if="event.loading" >
          <p>data laden</p>
      </div>

      <div v-else >
              <router-view/>
      </div>

    <VersieControle />

</template>


<script>


/*
module.exports = {

devServer: {
                    proxy: 'http://localhost:8081'
        },


  pwa: {
    name: 'The Colorfield Performance',

    iconPaths: {
            favicon: "ficons/favicon.png",
            favicon32: "ficons/favicon-32x32.png",
            favicon16: "ficons/favicon-16x16.png",
            appleTouchIcon: "ficons/apple-touch-icon.png",
  //          maskIcon: "/ficons/",
            msTileImage: "ficons/mstile-310x310.png"
},

    themeColor: '#8ec356',
    msTileColor: '#000000',
    appleMobileWebAppCapable: 'yes',
    appleMobileWebAppStatusBarStyle: 'black',

    // configure the workbox plugin
   // workboxPluginMode: 'InjectManifest',

    // workboxOptions: {
    //   // swSrc is required in InjectManifest mode.
    //   swSrc: 'dev/sw.js',
    //   // ...other Workbox options...
    // },


//https://realfavicongenerator.net

        manifestOptions: {
            icons: [
                    {
                        "src": "/ficons/android-chrome-192x192.png",
                        "sizes": "192x192",
                        "type": "image/png"
                    },
                    {
                        "src": "/ficons/android-chrome-512x512.png",
                        "sizes": "512x512",
                        "type": "image/png"
                    }
                ]
            }


  }
}

*/


// @ is an alias to /src
// import TcpIntro from '@/components/TcpIntro.vue'
// import TcpBezoekers from '@/components/TcpBezoekers.vue'
// import TcpDeelnemers from '@/components/TcpDeelnemers.vue'
//import TcpHoofdMenu from '@/components/TcpHoofdMenu.vue'
//import TaalKeuze from '@/components/TaalKeuze.vue'





// export default {
//   name: 'Tcp',
//   components: {
//     TcpIntro
//   }
// }


import { reactive, computed } from 'vue'
import store from '@/store'

import VersieControle from '@/components/VersieControle.vue'




export default {
  name: 'The Colorfieldperformance',

   components: {
    // TcpIntro,
    // TcpBezoekers,
    // TcpDeelnemers,
    //TcpHoofdMenu,
     VersieControle
  },

   
    setup () {

        const event = reactive ({

                // data: computed (() => { 
                //        return a;
                // }),

                    //bg_foto: computed (() => { return "@/assets/introfoto/DJI_0454A.jpeg"  }),


                loading: computed (() => { 



                      if ( store.state.vertaalLijst.length > 0 )
                      {
                              return false; 
                      }

                      return true;
                  }),


                  //  test: computed (() => { 

                  //     if ( store.state.vertaalLijst.length > 0 )
                  //     {
                  //             return store.state.vertaalLijst[0].code; 
                  //     }

                  //     return '-';
                  // }),
        });



            function setPaginaTitel () {

                    if ( store.state.projectKeuzeData ) { 

                          document.title = store.state.projectKeuzeData.naam [ event.taalkeuze ] + ' | ' + event.subrubrieken [ event.subrubriek ];
                    }  
            }


        return { event, setPaginaTitel };
    },



      mounted() {

            //    this.laadVertaling();

                this.setPaginaTitel ();

                store.dispatch ( 'laadVertaling' );   
      },

      updated() {

                //this.laadVertaling();

                store.dispatch ( 'laadVertaling' );   
      },

}



</script>






<style>


/*

@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;700&display=swap');
*/

/*@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap');
*/

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400;600&display=swap');






body
{
        width: auto;
}

#app 
{
      margin: 0;
      padding: 0;

    width: auto;

      font-family: 'Source Sans Pro', sans-serif;
 
      font-size: 1rem;
      line-height: 1.6em;
      font-weight: 400;


      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;

      color: rgb( 90, 90, 90 );
}


h1,
h2,
h3
{
        font-weight: 200;
        line-height: 1.3em;

        padding: 0 0 .2rem 0;
}


h1
{
       color: rgb( 195, 35, 295 );
       color: rgb(79, 100, 215);
}


/*-----------------------------------------------------*/
/*-----------------------------------------------------*/



.subnav 
{
        padding: 1rem 1rem 0 1rem;

        color: rgb( 255, 255, 255 );

}



/*-----------------------------------------------------*/


 

.button 
{
    display: inline-block;

    margin: 0 .3rem .3rem .3rem;
    padding: .1rem 1rem .1rem 1rem;

    font-size: .9em;
    
    font-weight: 400;
    line-height: 1.6rem;

    color: rgb( 160, 160, 160 );
    color: rgb( 90, 90, 90 );
    background-color: rgb( 255, 255, 255 );

   text-decoration: none;

    border-radius: 30px;
    border: 1px dashed rgb(209, 221, 255);
    border: 1px solid rgb(240, 240, 240 );
}


.button:hover 
{
      color: rgb( 90, 90, 90 );
      background-color: rgb( 230, 230, 230 );

      animation: buttonVergroot linear .5s;
      animation-timing-function: ease;
      animation-iteration-count: 1;
      transform-origin: 50% 50%;
      animation-fill-mode: forwards;     
}


.button.select
{
      background-color: rgb( 240, 240, 240 );
      background-color: rgb( 220, 220, 255 );
      background-color: rgb(209, 221, 255);

      border-style: solid;
}


@media (min-width: 40rem) 
{

              .subnav 
              {
                padding: 1rem 1rem 1rem 1rem;

                color: rgb( 255, 255, 255 );

              }


              .button 
              {
                  display: inline-block;

                  margin: 0 .5rem .5rem .5rem;
                  padding: .1rem 1rem .1rem 1rem;


                  font-weight: 400;
                  line-height: 2rem;

                  color: rgb( 160, 160, 160 );
                  color: rgb( 90, 90, 90 );
                  background-color: rgb( 255, 255, 255 );

                 text-decoration: none;

                  border-radius: 30px;
                  border: 1px dashed rgb(209, 221, 255);
                  border: 1px solid rgb(240, 240, 244 );
              }


              .button:hover 
              {
                    color: rgb( 90, 90, 90 );
                    background-color: rgb( 230, 230, 236 );

                    animation: buttonVergroot linear .5s;
                    animation-timing-function: ease;
                    animation-iteration-count: 1;
                    transform-origin: 50% 50%;
                    animation-fill-mode: forwards;     
              }


              .button.select
              {
                    background-color: rgb( 240, 240, 240 );
                    background-color: rgb( 220, 220, 255 );
                    background-color: rgb(209, 221, 255);
                    background-color: rgb( 240, 240, 244 );

                    border-style: solid;
              }

              /*-----------------------------------------------------*/
}






@keyframes buttonVergroot {

        0% {
              transform: scale(1, 1);
      }

        50% {
          transform: scale(1.04, 1.04);
        }

        100% {
          transform: scale(1, 1);
        }  
}


/*-----------------------------------------------------*/



body 
{
        margin: 0;
        padding: 0;

        background-color: rgb( 250, 250, 250 );
}

    


/*-----------------------------------------------------*/




.content 
{
        margin: 0 auto 70rem auto;
        padding: 0;

        width: auto;
        max-width: 80rem;

        text-align: center;

        background-color: rgb( 255, 255, 255 );

        min-height: 1200px;
}




@media (min-width: 50rem) 
{


/*.home_foto 
{
       background-image: url( ~@/assets/introfoto/home_zw.jpg );
}*/


      .content 
    {
             box-shadow: 0 0 12px rgb( 0, 0, 0 );
      }

}



.content  img
{
      width: 100%;
}




/*-----------------------------------------------------*/



    .slowShow
    {
          animation: slowShow linear .8s;
          animation-timing-function: ease-out;
          animation-iteration-count: 1;
          transform-origin: 50% 50%;
          animation-direction: alternate;
          animation-fill-mode: forwards;
    }




    @keyframes slowShow 
{
        0% {
/*                transform: translate(1500px, 0);
*/                opacity: .3;
        }


      100% {
/*        transform: translate(0, 0);
*/                opacity: 1;
      }  
}



/*-----------------------------------------------------*/



.video 
{

  width: 100%;

  max-height: 26rem;
}


.video-container 
{
  margin: 0 0 0 0;

  position:relative;
  padding-bottom:56.25%;
  padding-top: 2em;
  height: 0;
  overflow:hidden;
}


.video-container iframe, 
.video-container object, 
.video-container embed 
{
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
}


/*-----------------------------------------------------*/



/*-----------------------------------------------------*/

</style>




