
<template>

      <div class="versie">
        <p>V: {{event.versie}}</p>
    </div>
             
</template>


<script>




import { reactive, computed } from 'vue';
import store from '@/store'


export default {

    name: 'VersieControle',

    // props: {

    //      //   selectie: String,
    // },

    components: {
      //      LoadingImg
          
    },



    setup ( ) {

        const event = reactive ({

    //            imgIsLoaded: false,


                versie: computed ((  ) => { 

                     return store.state.siteVersie.v;
                }),


                // activeClass: 'lijst_select',
                // standClass: '',
        });


        function initPaginaData () {

                console.log( "VersieControle init data" );

                store.dispatch ( 'laadSiteVersie' ); 




                
        }



        return { 
              event, 
              initPaginaData,
        };
    },



          mounted () {

                this.initPaginaData ();

              //console.log( "VersieControle: mounted" );

               //event.imgIsLoaded = false;
          },


          updated () {

                this.initPaginaData ();

              //  this.initPaginaData ();

               //  console.log( "VersieControle: updated" );

               //event.imgIsLoaded = false;
          },


}



</script>


<style >


.versie 
{
     position:  absolute;
     width:  100%;

     text-align: right;
     bottom: 2px;
}


.versie p
{
     display: inline-block;

     padding:  0 1rem;

     font-size: .8rem;
     text-align: center;
     font-weight: 600;

     color: rgb( 100, 100, 200 );
     color:  rgba( 255, 255, 255, .5 );
}



</style>




