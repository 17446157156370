import { createStore } from 'vuex'
//import Vue from 'vue'



let siteImagesUrl = 'https://thecolorfieldperformance.nl/app_images_01/' ;
let appDataUrl = '/app-data/index.php' ;


if ( process.env.NODE_ENV !== 'production'  )
{
        siteImagesUrl   = 'http://dirkhakze.local/app_images_01/' ;
        appDataUrl             = 'http://dirkhakze.local/app-data/index.php' ;

      //alert ( 'local' );
}




export default createStore({

      state: {

                               //siteImagesUrl : 'https://walter.local/app_images_01/' ,
                  siteImagesUrl : siteImagesUrl,


           // appDataUrl : 'https://dirkhakze.local/app-data/index.php' ,
             // appDataUrl : 'https://thecolorfieldperformance.nl/app-data/index.php' ,
           //   appDataUrl : '/app-data/index.php' ,
                 appDataUrl: appDataUrl,

                     siteVersie: { 
                            status: false, 
                            t: '', 
                            v: '',
                      },


       //          taalLijst: [ "NL","DE","EN" ],

                 taalKeuze: 1,

                 menu_status: '',

                 vertaalLijst : [],

                 redactieTekstLijst : [],
                 redactieTekstKeuze: 0,
                //RedactieTekstKeuzeData: null,

                 projectLijst : [],
                 projectKeuze: 1,
                 projectKeuzeData: null,

                 projectTekst: [],

                 tekstSubrubriek: 0,

                 deelnemerLijst : [],
                 deelnemerAgenda : [],

                 sponsorLijst : [],

                 email_adres: "",
      },


    mutations: {



                    // setVoorkeuren ( state ) {

                    //            // //console.log ( "setVoorkeuren " );


                    //             //------------------------------------------//
                    //            // voorkeuren laden
                    //            //------------------------------------------//

                    //            if ( state.fotoKeuzeLijst.length == 0 )
                    //            {
                    //                      let lijst = localStorage.getItem ( 'fotoKeuzeLijst' ) ;

                    //                      if ( lijst !== "undefined" )
                    //                      {
                    //                                     state.fotoKeuzeLijst  = JSON.parse( lijst );
                    //                      }

                    //          /// localStorage.setItem ( 'fotoKeuzeLijst', state.fotoKeuzeLijst );

                    //            }
                    // },


                  //----------------------------------------------//


                    setSiteVersie ( state, data_geladen ) {


                          let versie = 0;


                            if ( typeof data_geladen !== "undefined" )
                            {
                                    if ( typeof data_geladen.data !== "undefined" )
                                    {
                                              if ( typeof data_geladen.data.versie !== "undefined" )
                                              {
                                                    versie = data_geladen.data.versie;
                                              }
                                    }     
                            }


                            //-----------------------------------------------------//
                            // data versie geladen - hetzelfde gebleven dus klaar
                            //-----------------------------------------------------//


                             if ( localStorage.getItem ( 'versie' ) == versie  )
                             {
                                     // this.loading = false;

                                      state.siteVersie.v              =  versie;
                                   //   state.siteVersie.t               = data_geladen.t ;  // tijd
                                      state.siteVersie.status   = true ;

                                      return;
                             }
           

                            //-----------------------------------------------------//
                            // data niet hetzelfde - opslaan en reload pagina
                            //-----------------------------------------------------//


                              state.siteVersie.v = versie;

                              localStorage.setItem ( 'versie', versie );

                              state.siteVersie.status   = true ;



                              //alert ( 'updaten scherm' );

                              console.log ( "updaten browser window" );



                               window.location.reload ( true );



                                navigator.serviceWorker.register('/sw.js').then(reg => {
                              // sometime later…
                                        console.log ( "updaten service worker" );
                                         reg.update();
                                });


                            //store.commit ( 'setEmail', localStorage.getItem ( 'email' ));

                            //-----------------------------------------------------//


                    },


            setMenu ( state, menu_status ) {

                    state.menu_status = menu_status;
            },


            setVertaalLijst ( state, data ) {

                    state.vertaalLijst = data;
            },


            setTaalKeuze ( state, id ) {

                    state.taalKeuze = id;

                    localStorage.setItem ( 'taal_keuze', id );

                    //            if ( state.fotoKeuzeLijst.length == 0 )
                    //            {
                    //                      let lijst = localStorage.getItem ( 'fotoKeuzeLijst' ) ;

                    //                      if ( lijst !== "undefined" )
                    //                      {
                    //                                     state.fotoKeuzeLijst  = JSON.parse( lijst );
                    //                      }

                    //          /// localStorage.setItem ( 'fotoKeuzeLijst', state.fotoKeuzeLijst );

            },


            setRedactieTekstLijst ( state, data ) {

                    state.redactieTekstLijst = data;
            },


            setRedactieTekstKeuze ( state, data ) {

                    state.redactieTekstKeuze = data;
            },


            setProjectLijst ( state, data ) {

                    state.projectLijst = data;
            },


            setProjectKeuze ( state, id ) {

                    state.projectKeuze = id;
            },


            setProjectKeuzeData ( state, data ) {
                
                    state.projectKeuzeData = data;
            },


            setProjectTekst ( state, data ) {

                    state.projectTekst = data;
            },


            setTekstSubrubriek ( state, nr ) {

                    state.tekstSubrubriek = nr;
            },


            setDeelnemerLijst ( state, data ) {

                    state.deelnemerLijst = data;
            },


            setDeelnemerAgenda ( state, data ) {

                let b = data.filter ( x => x.project_id == state.projectKeuze );


                let jaar = "2021";  

                //let jaar = this.getVertaling ( 'agenda_jaar' );


                    if ( state.appDataUrl != '' ) {
                        
                        let a = state.vertaalLijst.find ( record => record.code === 'agenda_jaar' )

                        if ( a !== null ) 
                        {
                                 if ( a.naam !== undefined )
                                {
                                    jaar =  a.naam;
                                }                           
                        }
                    }








                for ( let maand = 1 ;  maand <= 12 ; maand++ ) 
                {

                        for ( let dag = 1 ;  dag <= 31 ; dag++ )  
                        {

                            let datum = '' ;

                             if ( dag < 10 ) 
                                {
                                    datum  +=  '0' + dag + '-' ;
                                }
                                else
                                {
                                    datum  +=  dag +  '-' ;
                                }

                             if ( maand < 10 ) 
                                {
                                    datum   +=  '0' + maand + '-' ;
                                }
                                else
                                {
                                    datum  +=  maand + '-' ;
                                }

                                datum  +=  jaar ;


                                let c = b.filter ( x => x.datum_aanwezig_1== datum || x.datum_aanwezig_2== datum  || x.datum_aanwezig_3== datum );

                                if ( c.length > 0 )
                                {
                                        if ( state.deelnemerAgenda [ maand ] == undefined )
                                        {
                                            state.deelnemerAgenda [ maand ] = [];
                                        }

                                        state.deelnemerAgenda [ maand ][ dag ] = c;
                               }
                        }
                }
                
            },



            setSponsorLijst ( state, data ) {

                    state.sponsorLijst = data;
            },
    },



    getters: {

             getTaalKeuze: state => ( ) => {

                    if ( state.taalKeuze == 0 )  return 1;

                        return state.taalKeuze;
             },




             getVertalingVelden: state => ( code ) => {

                    if ( state.vertaalLijst != '' ) {
                        
                            let a = state.vertaalLijst.find ( record => record.code == code )

                            if ( a == null ) return [ '?' ];

                            if ( a.naam === undefined ) return [ '?' ];

                            //alert ( 'Buttons: ' + a.naam );

                            let b = [ '',  '',  '',  '',  '', ]
                            
                            b [ 0 ] = a.naam;
                            b [ 1 ] = a.naam_d;
                            b [ 2 ] = a.naam_e;
                            b [ 3 ] = a.naam_f;

                            //return a ;
                            return b;
                    }

                    return [ '?' ];
            },




             getVertaling: state => ( code ) => {

                    if ( state.appDataUrl != '' ) {
                        
                        let a = state.vertaalLijst.find ( record => record.code === code )

                        if ( a == null ) return code;

                        if ( a.naam == undefined ) return code;

                        if ( state.taalKeuze == 0 )  return a.naam;
                        if ( state.taalKeuze == 1 )  return a.naam;
                        if ( state.taalKeuze == 2 )  return a.naam_e;
                        if ( state.taalKeuze == 3 )  return a.naam_d;
                        if ( state.taalKeuze == 4 )  return a.naam_f;
                    }

                    return code ;
             },




             getProjectVeld: state => ( veld ) => {

                         let d = state.projectLijst.filter ( c => c.id == state.projectKeuze );

                         if ( d == null ) return '...loading';

                         if ( d [ 0 ][ veld ] == null ) return 'onbekend: '  + veld ;

                         if ( d [ 0 ][ veld ].length < 1 ) return '?';

                         return d [ 0 ][ veld ] [ state.taalKeuze ];
             },







                 // projectLijst : [],
                 // projectKeuze: 1,
                 // projectKeuzeData: null,



             getRedactieTekst: state => ( code ) => {

                        let a = state.redactieTekstLijst.find ( record => record.naam === code )

                        if ( a == null ) return code;

                        if ( a.naam.length < 1 ) return code;

                        return a.body;
             },




             getTekstActueel: state => ( doelgroep, tekst_id ) => {

                      if ( state.projectTekst.length == 0 )
                      {
                             return '';
                      }


                      //-------------------------------------------//
                      // zoek de tekst op
                      //-------------------------------------------//


                  //  let taalkeuze = this.state.getTaalKeuze()

                    //alert ( "taal: " + taalkeuze )

                     if ( state.taalKeuze == 0 )  state.taalKeuze = 1



                      let a = state.projectTekst;

                      let d = a.filter ( c => c.project_id == state.projectKeuze );

                       let e = d.filter ( c => c.doelgroep == doelgroep );



                       if (  tekst_id > 0  ) // kijk of de tekst ertussen staat
                       {
                                 let f = e.filter ( c => c.id == tekst_id );

                                  if ( f [ 0 ]  !== undefined )
                                  {
                                          // alert ( 'tekst aanwezig: ' + f[0].id ) ;                          

                                           return  f [ 0 ].body [ state.taalKeuze ];
                                  }
                        }


                       //-------------------------------------------//
                       // niet gevonden, stel in op de eerste in de lijst
                       //-------------------------------------------//

                       // alert ( 'tekst == 0 ');


                     let g = e.filter ( cg => cg.body [ state.taalKeuze ] != '' );

                      if ( g [ 0 ]  !== undefined )
                      {
                              // alert ( 'tekst aanwezig: ' + f[0].id ) ;                          

                               return  g [ 0 ].body [ state.taalKeuze ];
                      }

                        if ( g [ 0 ]  !== undefined )
                        {
                           //   alert ( 'eerste tekst: ' + e[0].id ) ;

                                 if ( g [ 0 ].id  !== undefined )
                                 {
                                         //tekst_id = e [ 0 ].id;

                                         return  g [ 0 ].body [ state.taalKeuze  ];                             
                                 }
                        }

                         return '-';
            },




            getRedactieFotos: state => ( code ) => {

                    // if ( state.appDataUrl != '' ){

                        let a = state.redactieTekstLijst.find ( record => record.naam === code )

                        if ( a == null ) return [];

                        if ( a.naam == undefined ) return [];

                        return a.beeld;
            },
    },



      actions: {


                    //----------------------------------------------//


                     async laadSiteVersie ( context ) {

                                //alert ( 'laad laadTekst' );

                                if ( this.state.siteVersie.status   )
                                {
                                   // alert ( 'vertaling is al geladen' );

                                     ////console.log( "laadSiteVersie is al geladen" );

                                    return;  // is al geladen
                                }

                                 console.log( "laadSiteVersie en basisinstellingen" );

                                this.state.siteVersie.status = false;



                               //------------------------------------------//
                               // taalkeuze herinneren
                               //------------------------------------------//


                                let tk = localStorage.getItem ( 'taal_keuze' );

                                if ( tk > 0 )
                                {
                                        context.commit ( 'setTaalKeuze', tk );
                                }


                               //------------------------------------------//


                                let url = this.state.appDataUrl + '?t=versie';

                               ///alert ( url );

                                let res     = await fetch ( url  );
                                let data  = await res.json();


                                context.commit ( 'setSiteVersie', data );


                                //context.commit ( 'setVoorkeuren' ); // favorieten

                                ////console.log ( "SiteVersie - geladen" );

                                //if ( this.state.tekstKeuze > 0 ) context.commit ( 'setTekstKeuze',  this.state.tekstKeuze );

                               //------------------------------------------//
                      },


                    setMenuStatus ( context, menu_status ) {

                    context.commit ( 'setMenu', menu_status )
            },       


             wisselMenuStatus ( context ) {

                    if ( this.state.menu_status == 'open' )
                    {
                        context.commit ( 'setMenu', 'close' )
                    }
                    else
                    {
                        context.commit ( 'setMenu', 'open' )
                    }
            },   


             setTekstSubrubriek ( context, nr ) {

                    context.commit ( 'setTekstSubrubriek',  nr )
            },   


            async laadVertaling ( context ) {

                       // alert ( 'laad vertaling' );

                        if ( this.state.vertaalLijst.length > 0   )
                        {
                           // alert ( 'vertaling is al geladen' );
                            return;  // is al geladen
                        }


                       //------------------------------------------//


                        let url = this.state.appDataUrl + '?t=vertaling';

                        let res = await fetch ( url  );

                        let data = await res.json();

                        context.commit ( 'setVertaalLijst', data );


                       //------------------------------------------//
            },



             setTaalKeuze ( context, id ) {

                     context.commit ( 'setTaalKeuze',  id );
            },




          async laadRedactieTekst ( context ) {

                        // de teksten die aan een project hangen

                        if ( this.state.redactieTekstLijst.length > 0  )
                        {
                            return;
                        }

                       //------------------------------------------//

                        let url = this.state.appDataUrl + '?t=dirk_tekst';

                        let res = await fetch ( url  );

                        let data = await res.json();

                        context.commit ( 'setRedactieTekstLijst', data );

                       // alert ( 'geladen: setRedactieTekstLijst' );

                       //------------------------------------------//
                  },




                setRedactieTekstKeuze ( context, id ) {
        
                        // test eerst of deze er wel is

                        let d = this.state.redactieTekstLijst.filter ( c => c.id == id );

                        if ( d.length > 0 )
                        {
                                    context.commit ( 'setRedactieTekstKeuze', id );

                                    // de eerste in de lijst met die selectie is de goede

                                    if ( d [ 0 ].naam !== undefined )
                                    {
                                        context.commit ( 'setRedactieTekstData', d [ 0 ] );

                                        return;
                                    }
                        }

                        // niet gevonden dan gewoon de eerste

                         if ( this.state.redactieTekstLijst.length > 0 )
                         {
                                context.commit ( 'setRedactieTekstData',         this.state.redactieTekstLijst [ 0 ] );
                                context.commit ( 'setRedactieTekstKeuze',     this.state.redactieTekstLijst [ 0 ].id );
                         }
                },



                //-------------------------------------------------------------------//



                setProjectKeuze ( context, id ) {
        
                        // test eerst of deze er wel is

                        let d = this.state.projectLijst.filter ( c => c.id == id );

                        if ( d.length > 0 )
                        {
                                    context.commit ( 'setProjectKeuze', id );

                                    // de eerste in de lijst met die selectie is de goede

                                    if ( d [ 0 ].naam !== undefined )
                                    {
                                        context.commit ( 'setProjectKeuzeData', d [ 0 ] );

                                        return;
                                    }
                        }

                        // niet gevonden dan gewoon de eerste

                         if ( this.state.projectLijst.length > 0 )
                         {
                                context.commit ( 'setProjectKeuzeData',         this.state.projectLijst [ 0 ] );
                                context.commit ( 'setProjectKeuze',                 this.state.projectLijst [ 0 ].id );
                         }
                },


                async laadProjecten ( context ) {

                        if ( this.state.projectLijst[0] != undefined )
                        {
                            return;  // is al geladen
                        }

                       //------------------------------------------//

                        let url = this.state.appDataUrl + '?t=projecten';

                        let res = await fetch ( url  );

                        let data = await res.json();

                        context.commit ( 'setProjectLijst', data );

                       //------------------------------------------//

                       this.dispatch ( 'setProjectKeuze', 0 ); // fake waarde: zoek automatisch eerste op
                },



                async laadProjectTekst ( context ) {

                        // de teksten die aan een project hangen

                        if ( this.state.projectTekst.length > 0  )
                        {
                            return;
                        }

                       //------------------------------------------//

                        let url = this.state.appDataUrl + '?t=project_tekst';

                        //alert ( url );

                        let res = await fetch ( url  );

                        let data = await res.json();

                        context.commit ( 'setProjectTekst', data );

                       //------------------------------------------//
                },


                async laadDeelnemers ( context ) {

                        if ( this.state.deelnemerLijst[0] != undefined  )
                        {
                            return; // is al geladen
                        }

                       //------------------------------------------//

                        let url = this.state.appDataUrl + '?t=deelnemers';

                        let res = await fetch ( url  );

                        let data = await res.json();

                        context.commit ( 'setDeelnemerLijst', data );

                        context.commit ( 'setDeelnemerAgenda', data );

                       //------------------------------------------//
                },


                async laadSponsors ( context ) {

                        if ( this.state.sponsorLijst [ 0 ] != undefined  )
                        {
                            return;  // is al geladen
                        }

                       //------------------------------------------//

                        let url = this.state.appDataUrl + '?t=sponsors';

                        let res = await fetch ( url  );

                        let data = await res.json();

                        context.commit ( 'setSponsorLijst', data );

                       //------------------------------------------//
                  },

        },

        modules: {

      }
})


