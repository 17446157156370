import { createRouter, createWebHashHistory } from 'vue-router'
//import Tcp from '../views/Tcp.vue'

const routes = [
{
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "project" */ '../views/Home.vue'),
        meta: { title: 'The Colorfield Performance' }

},

{
        path: '/thecolorfieldperformance',
        name: 'Project',

        component: () => import(/* webpackChunkName: "project" */ '../views/Tcp.vue')
},

{
          path: '/dirkhakze',
          name: 'Dirk Hakze',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "about" */ '../views/DirkHakze.vue')
}
]


const router = createRouter({

  history: createWebHashHistory(),
  routes
})

export default router





